






import Vue from 'vue';
import { TranslateServiceBuilder } from '../../services/Translate/translateServiceBuilder';

export default Vue.component('QTranslate', {
  props: {
    textKey: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    text (): string {
      return TranslateServiceBuilder.Instance()._(this.textKey, this.placeholder);
    },
  },
})
