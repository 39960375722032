export class Shop {
    name: string;
    id: string;
    is_naizei: boolean;
    jp_zip: string;
    address: string;
    phone: string;
    business_hours: string;
    holiday: string;
    image: string;
    has_guide: boolean;
    last_order_times: lastOrderTime[];
    is_closed: boolean = false;
    is_enable: boolean = false;
    expired_date: string;
    is_select_receive: boolean = false;
}

export class lastOrderTime {
    id: string;
    time: string;
    release_time: string;
    is_enable: boolean;
}
